
/**
 * Is exist element
 * @return {[type]} [description]
 */
$.fn.exists = function() {
    return this.length !== 0;
}

/**
 * Is empty object
 * @param  {Object}  obj [description]
 * @return {Boolean}     [description]
 */
$.fn.isEmpty = function() {
	if (this == null) return true;
	if (this.length > 0)    return false;
	if (this.length === 0)  return true;
	for (var key in this) {
		if (hasOwnProperty.call(this, key)) return false;
	}
	return true;
}

/**
 * jQuery plugin for Sortable
 * @param   {Object|String} options
 * @param   {..*}           [args]
 * @returns {jQuery|*}
 */
$.fn.sortable = function(options) {
	var retVal,
		args = arguments;

	this.each(function () {
		var $el = $(this),
			sortable = $el.data('sortable');

		if (!sortable && (options instanceof Object || !options)) {
			sortable = new Sortable(this, options);
			$el.data('sortable', sortable);
		}

		if (sortable) {
			if (options === 'widget') {
				return sortable;
			}
			else if (options === 'destroy') {
				sortable.destroy();
				$el.removeData('sortable');
			}
			else if (typeof sortable[options] === 'function') {
				retVal = sortable[options].apply(sortable, [].slice.call(args, 1));
			}
			else if (options in sortable.options) {
				retVal = sortable.option.apply(sortable, args);
			}
		}
	});

	return (retVal === void 0) ? this : retVal;
};

/**
 * Parse youtube short/ mebed url to "normal" url in href attr
 * @return {[type]} [description]
 */
$.fn.youtubeParse = function() {
	$.each(this, function(index, val) {
		var url    = $(val).attr('href').toString();

		var regExp = /^(<iframe.*? src=")(.*?)(\??)(.*?)(".*)$/;
		var match  = url.match(regExp);
		if(match) url = match[4];

		var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
		var match  = url.match(regExp);

		if(match && match[7].length==11) $(val).attr('href', 'https://www.youtube.com/watch?v=' + match[7]);
	});

	// if( !this.exists() ) return false;
	// var url    = this.attr('href');
	// var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
	// var match  = url.match(regExp);
	// if(match&&match[7].length==11) this.attr('href', 'https://www.youtube.com/watch?v=' + match[7]);
	// return (match&&match[7].length==11)? 'https://www.youtube.com/watch?v=' + match[7] : false;
}

$.fn.youtubeImage = function() {
	$.each(this, function(index, val) {
		var img      = $(val).attr('src');
		var is_image = img.toLowerCase().match('/gif|jpg|jpeg|png/');

		if(is_image === null) {
			var url = img.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
			if(url[2] !== undefined) {
				id = url[2].split(/[^0-9a-z_\-]/i);
				id = id[0];

				img = 'http://img.youtube.com/vi/'+id+'/0.jpg';
			}
			else {
				img = 'http://img.youtube.com/vi/T0Jqdjbed40/0.jpg';
			}

			$(val).attr('src', img);
		}
	});
}